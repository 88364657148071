<template>
  <div class="container">
    <div class="card card-body">
      <div class="myform card-form-input">
        <form>
          <label class="mt-5">
            日付 (日本標準時間)
          </label>
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Date" type="date">
          </div>

          <label class="mt-3">
            体温
          </label>
          <div class="input-group mb-3">
            <select aria-label="Default select example" class="form-select">
              <option value="37.3">37.3</option>
              <option value="37.2">37.2</option>
              <option value="37.1">37.1</option>
              <option value="37.0">37.0</option>
              <option value="36.9">36.9</option>
              <option selected>----</option>
              <option value="36.8">36.8</option>
              <option value="36.7">36.7</option>
            </select>
            <div class="input-group-append">
              <span id="basic-addon2" class="input-group-text">&#8451;</span>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              平熱を超える発熱
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio1" checked class="form-check-input"
                       name="isFever"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio2">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio3" class="form-check-input" name="isFever"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio3">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              咳やのどの痛みなど風邪の症状
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio4" checked class="form-check-input"
                       name="isCold"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio4">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio5" class="form-check-input" name="isCold"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio5">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              だるさ（倦怠感）、息苦しさ（呼吸困難）
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio6" checked class="form-check-input"
                       name="isDullness"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio6">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio7" class="form-check-input" name="isDullness"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio7">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              嗅覚や味覚の異常
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio8" checked class="form-check-input"
                       name="isAbnormal"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio8">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio9" class="form-check-input" name="isAbnormal"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio9">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              体が重く感じる、疲れやすい等
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio10" checked class="form-check-input"
                       name="isTired"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio10">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio11" class="form-check-input" name="isTired"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio11">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              新型コロナウイルス感染症陽性とされた者との濃厚接触の有無
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio12" checked class="form-check-input"
                       name="isInfected"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio12">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio13" class="form-check-input" name="isInfected"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio13">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              同居家族や身近な知人に感染が疑われる方がいる場合
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio14" checked class="form-check-input"
                       name="isSuspectInfected"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio14">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio15" class="form-check-input" name="isSuspectInfected"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio15">無し</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="mt-3">
              過去 14 日以内に政府から入国制限、入国後の経過観察を必要とされている国、地域への渡航又は当該在住者との濃厚接触がある場合
            </label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="inlineRadio16" checked class="form-check-input"
                       name="isTravelled"
                       type="radio"
                       value="option1">
                <label class="form-check-label" for="inlineRadio16">有り</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="inlineRadio17" class="form-check-input" name="isTravelled"
                       type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio17">無し</label>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label" for="exampleFormControlTextarea1">
              備考欄:<br>健康状態で気になることがあれば記載してください
            </label>
            <textarea id="exampleFormControlTextarea1" class="form-control" rows="5"></textarea>
          </div>
          <div class="form-check mt-1 custom-accept-checkbox">
            <input id="flexCheckDefault" class="form-check-input" type="checkbox" value="">
            <label class="form-check-label mx-2" for="flexCheckDefault">
              記載内容に間違いありません
            </label>
          </div>
          <div class="mt-3 d-grid gap-2">
            <button class="btn custom-btn" type="button">送信</button>
          </div>

        </form>
      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: "InputHealthCondition"
}

</script>

<style scoped>

.card {
  margin-bottom: 111px;
}

.card-form-input {
  max-width: 720px;
  margin: 0 auto;
}

input[type="date"] {
  background-color: #D9E5F7;
  height: 48px;
}

select {
  height: 48px;
}

.input-group-text {
  height: 48px;
}

label {
  line-height: 30px;
  font-size: 16px;
  color: #333333;
}

.form-check-inline:first-child {
  margin-right: 60px;
}

textarea {
  background: #D9E5F7;
}

.custom-accept-checkbox {
  text-align: center;
  padding: 10px 0px;
}

.custom-accept-checkbox input {
  float: none;
}

.custom-btn {
  background: #2663D1;
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  height: 44px;
  width: 350px;
}

</style>